#notfound {
    position: relative;
    height: 100vh;
    background: #f6f6f6;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    padding: 20px 20px;
    text-align: center;
    background: #fff;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 180px;
  }
  
  .notfound .notfound-404 h1 {
    
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 165px;
    font-weight: 700;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
  }
  
  .notfound .notfound-404 h1>span {
    color:rgba(0, 123, 255, 1);
  }
  
  .notfound h2 {
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    color: #151515;
    margin-top: 0px;
    margin-bottom: 25px;
  }