html {
  scroll-behavior: smooth;
}

/* General Tags */
.generalClass-h {
  color: #007bff;
  color: rgba(0, 123, 255, 1);
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
  line-height: 1.125;
}

.h1-class {
  font-size: 3.5rem;
}
.h2-class {
  font-size: 3rem;
}
.h3-class {
  font-size: 2rem;
}
.h4-class {
  font-size: 1.5rem;
}

.h4-h5-h6 {
  font-weight: 500;
  line-height: 1.25;
}

/* ANIMATION */

.fade-up,
.fade-right {
    opacity: 0;
    transform: translate(0, 4rem);
    transition: all 1s;
}

.fade-up {
    transform: translate(0, 4rem);
}

.fade-right {
    transform: translate(4rem, 0);
}

.fade-up.visible,
.fade-right.visible {
    opacity: 1;
    transform: translate(0, 0);
}

/* RESPONSIVE TEXT */
@media (max-width: 991px) {
  .h1-class {
    font-size: 3.125rem;
  }
  .h2-class {
    font-size: 2.625rem;
  }
  .h3-class {
    font-size: 1.625rem;
  }
  .h5-class {
    font-size: 1.125rem;
  }
  .body-class {
    font-size: .875rem;
  }
}

/* Custom Header */
.telecare-header-landing {
  background: linear-gradient(
    45deg,
    rgba(0, 123, 255, 1) 0%,
    rgba(77, 199, 160, 1) 100%
  );
  color: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,.25);
}

.telecare-header-landing .navbar-nav-static {
  flex-direction: row;
}

.telecare-header-landing .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75);
    padding: .5rem 1rem;
}

.telecare-header-landing .navbar-nav .btn {
    margin: 0 1rem 0 .5rem;
}

.telecare-header-landing .navbar-brand,
.telecare-header-landing .navbar-nav .active > .nav-link,
.telecare-header-landing .navbar-nav .nav-link.active,
.telecare-header-landing .navbar-nav .nav-link.show,
.telecare-header-landing .navbar-nav .show > .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 575px) {
    .navbar-toggler-icon {
      width: 1em;
      height: 1em;
    }
  }

.custom-gradient-bg-landing {
  background: linear-gradient(
    -45deg,
    rgba(0, 123, 255, 1) 0%,
    rgba(77, 199, 160, 1) 100%
  );
}

/* CONTAINERS */


/* @media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
} */

/* @media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
} */

/* @media (min-width: 768px) {
  .container {
      max-width: 92.5% !important;
  }
} */



@media (max-width: 575px) {
  .new-container {
      max-width: 92.5% !important;
  }
}

@media (min-width: 992px) {
  .new-container {
    max-width: 92.5% !important;
  }
}
@media (max-width: 1060px) {
  .new-container {
      max-width: 92.5% !important;
  }
}
@media (min-width: 1200px) {
  .new-container {
    max-width: 1140px !important;
  }
}

@media (max-width: 575px) {
  .container {
      max-width: 92.5% !important;
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 92.5% !important;
  }
}

@media (max-width: 1060px) {
  .container {
      max-width: 92.5% !important;
  }
}

@media (min-width: 1200px) {
  .container {
      max-width: 1140px !important;
  }
}


/* BUTTONS */
.btn-landing{
  border-radius: 1rem;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: .375rem .75rem;
}

.btn-sm-landing {
	border-radius: .75rem;
}

.btn-primary-landing {
  background-color: #ef4136;
  border-color: #ef4136;
  color: #fff;
  display: inline-block;
}

.btn-primary-landing:hover, .btn-primary-landing:focus, .btn-primary-landing:active, .btn-primary-landing.active, .open>.dropdown-toggle.btn-primary-landing {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* HYPER LINKS*/
.a-landing {
  text-decoration: none !important;
}

/* ALERTS */
.alert-icon {
  padding-left: 2rem;
}

.alert-info {
  background: linear-gradient( -45deg, rgba(0, 123, 255, 1) 0%, rgba(77, 199, 160, 1) 100%);
  color: #fff;
}

.alert h4 {
  color: #fff;
}

.alert small {
  font-weight: 200;
}

.alert-warning {
  color: #5a4915;
  background-color: #f2d063;
  border-color: #f2d063;
}

.alert-special {
  background-color: #ef4136;
  margin: 0;
  border-radius: 0;
}

.alert-special a {
  text-decoration: underline;
}

.alert .fa:first-child {
  background-color: #ef4136;
  padding: .5rem .875rem;
  border-radius: 50%;
  border: 1px solid #fff;
  margin-right: .5rem;
  position: absolute;
  left: -1rem;
  top: .5rem;
}

.fa-landing{
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-style: normal;
  line-height: normal;
}

/* COLORS */
.bg-primary-landing {
  background-color: #ef4136 !important;
}

/* FOOTER */
/* .footer h4,
.footer h5,
.footer h6,
.footer .h1,
.footer .h2,
.footer .h3,
.footer .h4,
.footer .h5,
.footer .h6 {
  color: #fff;
}

.footer img {
    max-width: 150px;
} */

/* DOWNLOAD APP LOGOS */

.download-app img {
  max-width: 240px;
}

@media (max-width: 991px) {
  .download-app img {
      max-width: 200px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .download-app img {
      max-width: 180px;
  }
}

/* LIST GRID */
.list-grid-landing {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: .5rem;
  margin-bottom: 3rem;
  padding-left: 2rem;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

@media only screen and (max-width: 1060px) {
  .list-grid-landing {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 576px) {
  .list-grid-landing {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* showcase */
.showcase {
  padding-bottom: 3rem;
  padding-top: 6rem;
  counter-reset: count;
}

.showcase .card {
  border-radius: 1rem;
  margin-bottom: 3rem;
  flex-direction: row;
  border: none;
  margin-left: 6rem;
}

.showcase .card:before {
  counter-increment: count;
  content: counter(count);
  width: 3rem;
  position: absolute;
  background-color: #ef4136;
  height: 3rem;
  margin-left: -4.5rem;
  border-radius: 50%;
  margin-top: 1rem;
  border: 1px solid #fff;
  z-index: 10;
  color: #fff;
  left: 0;
  line-height: 3rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.showcase .card:after {
  content: "";
  width: 2px;
  position: absolute;
  background-color: rgba(255,255,255,.8);
  height: calc(100% + 3rem);
  left: 0;
  margin-left: -3rem;
}

.showcase .card:last-child:after {
  height: calc(100%);
}

/* .showcase .card:nth-of-type(even) {
  flex-direction: row;
} */

.showcase .card-body {
  width: 75%;
  padding: 1.25rem;
  flex: 1 1 auto;
  min-height: 1px;
}

/* .showcase .card-body .btn-block-landing {
  width: 75%;
} */

.showcase .card-text-landing {
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 1.2rem;
}

.showcase .card-title-landing + .card-text-landing {
  margin-top: -.5rem;
}

.showcase .card .img-holder {
	width: 14rem;
	height: auto;
  overflow: hidden;
  background: #dfe7f2;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.showcase .card.no-numbers {
  margin-left: 0;
}

.showcase .card.no-numbers:before,
.showcase .card.no-numbers:after {
  display: none;
}

/* .showcase .card:nth-of-type(odd) .img-holder {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.showcase .card:nth-of-type(even) .img-holder {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
} */

.showcase .card .img-holder > img {
	width: 100%;
	height: auto;
  position: relative;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.showcase .card .img-holder > .svg-special {
	width: 150%;
    left: -30%;
}


@media (max-width: 991px) {
  .showcase .card {
    margin-left: 0;
  }

  .showcase .card .img-holder > img {
    width: 18rem;
    margin-left: -2.5rem
  }

  .showcase .card:before {
    margin-left: -1.5rem;
    margin-top: 1rem;
}

  .showcase .card:after {
    display: none;
  }

}

@media only screen and (max-width: 767px) {
    .showcase .card {
      flex-direction: column;
  }

  .showcase .card .img-holder {
    width: 100%;
    height: 18rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0;
  }

  .showcase .card .img-holder > img {
    width: 100%;
    margin: 0;
  }

  .showcase .card-body {
      width: 100%;
  }

  .showcase .card-body .btn-block {
      width: 100%;
  }
}

@media (max-width: 767px) {
  .showcase .card .img-holder {
    width: 100%;
  }

  .showcase .card:nth-of-type(even),
  .showcase .card:nth-of-type(odd) {
    flex-direction: column;
  }
  .showcase .card:nth-of-type(even) .img-holder,
  .showcase .card:nth-of-type(odd) .img-holder {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1rem;
  }
}

/* MASTHEAD */

header.masthead {
    background-image: url(../img/telmdcare-homepage-header.webp);
    background-position: right;
    background-size: cover;
    font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.header.masthead h1 {
    font-size: 3.5rem;
}

.masthead p {
    font-size: 1.625rem;
    font-weight: 200;
    max-width: 720px;
    padding-right: 1rem;
    line-height: 1.75;
}

.masthead .hero-img {
    z-index: 1020;
    position: relative;
    width: 380px;
    height: 575px;
}

.masthead .btn-lg {
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;
}

@media (min-width: 768px) and (max-width: 991px) {
    .header.masthead h1 {
        font-size: 3.125rem;
    }
    .header.masthead h1 span {
        display: block;
    }
    .masthead .hero-img {
        margin-left: 0;
        width: 300px;
        margin-bottom: auto;
        height: 454px;
    }
    .masthead .btn-lg {
        font-size: 1.25rem;
        padding: 0.5rem 1rem;
    }
}

@media (max-width: 767px) {
    .masthead p {
        padding-right: 0;
    }
    .masthead .hero-img {
        margin: 0 auto auto;
        max-width: 280px;
        height: 420px;
    }
}

@media (max-width: 575px) {
    .masthead .hero-img {
        margin: 0 auto auto -2rem;
    }
}
header.masthead {
  background-image: url(../img/telmdcare-homepage-header.webp);
  background-position: right;
  background-size: cover;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.header.masthead h1 {
  font-size: 3.5rem;
}

.masthead p {
  font-size: 1.625rem;
  font-weight: 200;
  max-width: 720px;
  padding-right: 1rem;
  line-height: 1.75;
}

.masthead .hero-img {
  z-index: 1020;
  position: relative;
  width: 380px;
  height: 575px;
}

.masthead .btn-lg {
  font-size: 1.5rem;
  padding: 0.75rem 1.5rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  .header.masthead h1 {
      font-size: 3.125rem;
  }
  .header.masthead h1 span {
      display: block;
  }
  .masthead .hero-img {
      margin-left: 0;
      width: 300px;
      margin-bottom: auto;
      height: 454px;
  }
  .masthead .btn-lg {
      font-size: 1.25rem;
      padding: 0.5rem 1rem;
  }
}

@media (max-width: 767px) {
  .masthead p {
      padding-right: 0;
  }
  .masthead .hero-img {
      margin: 0 auto auto;
      max-width: 280px;
      height: 420px;
  }
}

@media (max-width: 575px) {
  .masthead .hero-img {
      margin: 0 auto auto -2rem;
  }
}


.logo-font img {
  max-width: 180px;
  width: 100%;
}

@media (max-width: 767px) {

  .logo-font img {
      max-width: 120px;
  }
}

.as-seen-logo-brands img {
  max-width: 100px;
  min-width: 40px;
  width: 100%;
}

@media (min-width: 768px) {
  .md-and-up {
    display: block;
  }
  
  .sm-and-down {
    display: none;
  }
}

@media (max-width: 767px) {
  .md-and-up {
    display: none;
  }
  
  .sm-and-down {
    display: block;
  }
}

@media (max-width: 991px) {
  .as-seen-logo-brands img {
    max-width: 60px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .as-seen-logo-brands img {
    /* max-width: 45px;
    min-width: 40px; 
    max-height: 45px;
    min-height: 40px; 
    height: 40px;
    width: 40px; */
    display: block;
  }
}

/* INSURANCE */

.insurance-logos {
  margin: auto;
  width: 100%;
  max-width: 200px;
}

@media (max-width: 991px) {
  .insurance-logos {
      max-width: 180px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .insurance-logos {
      max-width: 150px;
  }
}


/* DOC NOTE COVER */

.doctors-note-cover {
  background-image: url("../img/doctor-note-photo.webp");
  background-size: cover;
  background-position: center bottom;
  min-height: 400px;
  position: relative;
}

.doctors-note-cover:after {
  content: "";
  background-color: rgba(255, 255, 255, .6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}

.doctors-note-cover h2,
.doctors-note-cover h4 {
  color: #333 !important;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.95);
}


/* PHYSICIANS */
.physicians {
  margin: -6rem 0 -12rem;
}

.masthead .physicians {
  margin: 3rem 0 -10.5rem;
}

.physician-carousel .item {
  padding: 1rem;
}

.physician-carousel .physician-item {
  background-color: #dfe7f2;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
  border-radius: 1rem;
  display: flex;
  height: 12rem;
}

.physician-carousel .physician-item .physician-img-wrapper {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  max-width: 12rem;
  overflow: hidden;
}

.physicians + .showcase {
  padding-top: 9rem;
}


@media (max-width: 1199px) { 
	header.masthead {
    background-size: cover;
    padding: 6rem 0 2rem;
	}

	.masthead .physicians {
		margin: 2rem 0 -9.5rem;
	}
 }

 @media (max-width: 991px) {
    .masthead .physicians {
      margin: 4rem 0 -12rem;
    }

    .physician-carousel .item {
      padding: 0;
    }

    .physician-carousel .physician-item .physician-img-wrapper {
      max-width: calc(12rem - 4rem);
      width: 100%;
    }

    .physician-carousel .physician-item .physician-img-wrapper img {
      display: inline-block;
      width: 200px;
      margin-left: -2rem;
    }
    
  }

 @media (max-width: 919px) {
    .physician-carousel .physician-item {
      height: 10rem;
    }
  }

 @media (max-width: 575px) {
    .physician-carousel .physician-item .physician-img-wrapper {
        max-width: calc(10rem - 5rem);
        width: 100%;
    }
    
    .physician-carousel .physician-item .physician-img-wrapper img {
        width: 160px;
      margin-left: -2.5rem;
    }
  }


/* SERVICES */
.services {
	background-color: #dfe7f2;
	padding-top: 3rem;
	padding-bottom: 0;
}

@media (min-width: 768px) and (max-width: 992px) {
    .services .bg-dark {
      max-height: 13rem;
  }
}

/* COMPARE */
.compare {
	margin-top: -9rem;
    margin-bottom: -10rem;
}

.compare .card{
	font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
	background: linear-gradient(
		-45deg,
		rgba(0, 123, 255, 1) 0%,
		rgba(77, 199, 160, 1) 100%
	);
	color: #fff;
	border: none;
    min-height: 16rem;
    border-radius: 1rem;
}

.compare .progress {
    height: 33px;
    border-radius: 0;
    border: 1px solid #fff;
    margin: 0 .5rem;
    font-size: 87.5%;
}

.compare .progress-bar {
  text-align: left;
  padding: 0 .75rem;
}

/* TESTIMONIALS */

.testimonials {
  padding-top: 10rem;
  padding-bottom: 6rem;
}

.testimonial-carousel .item {
  padding: .5rem;
}

.testimonial-carousel .testimonial-item {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 20rem;
  padding: 1rem;
  max-width: 100%;
}

.testimonial-carousel .testimonial-item h5 {
  font-weight: 400;
  color: #333;
}

.testimonial-item .btn-link img {
  box-shadow: none;
  max-width: 20px;
  display: inline-block;
  margin: 0 0.25rem;
}

@media only screen and (max-width: 1200px) {
  .testimonial-carousel .testimonial-item {
      height: 26rem;
  }
}

@media only screen and (max-width: 1060px) {
  .testimonial-carousel .testimonial-item {
      height: 22rem;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-carousel .testimonial-item {
      height: 20rem;
  }
}


/* STAR RATING STYLING */

.review-rating {
  color: #dfe7f2;
}

.review-rating .star-selected {
  color: #ef4136;
}

.review-rating+small {
  padding: 0.25rem 0.5rem;
}

/* CHATBOT */
/* .chatbot {
  position: fixed;
  bottom: 0.75rem;
  right: 1rem;
  z-index: 1000;
  opacity: 0.9;
  margin: 0 !important;
}

.chatbot .btn {
  width: 6rem;
  height: 6rem;
  line-height: 6.5rem;
  color: #fff !important;
  border-radius: 50%;
} */

/* UTILITIES */
.rounded {
  border-radius: 1rem !important;
}

.text-red {
  color: #ef4136;
}

@media (max-width: 575px) {
  .col-xs-4 {
      -ms-flex: 0 0 33.333%;
      flex: 0 0 33.333%;
      max-width: 33.333%;
  }
  .col-xs-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
}