/*!
 * Start Bootstrap - Landing Page v5.0.7 (https://startbootstrap.com/template-overviews/landing-page)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-landing-page/blob/master/LICENSE)
 */

 body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.375;
}

.telecare-header {
  background: linear-gradient(
    45deg,
    rgba(0, 123, 255, 1) 0%,
    rgba(77, 199, 160, 1) 100%
  );
  color: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,.25);
}

.telecare-header .navbar-nav-static {
  flex-direction: row;
}

.telecare-header .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75);
    padding: .5rem 1rem;
}

.telecare-header .navbar-nav .btn {
    margin: 0 1rem 0 .5rem;
}

.telecare-header .navbar-brand,
.telecare-header .navbar-nav .active > .nav-link,
.telecare-header .navbar-nav .nav-link.active,
.telecare-header .navbar-nav .nav-link.show,
.telecare-header .navbar-nav .show > .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 575px) {
    .navbar-toggler-icon {
      width: 1em;
      height: 1em;
    }
  }

.custom-gradient-bg {
  background: linear-gradient(
    -45deg,
    rgba(0, 123, 255, 1) 0%,
    rgba(77, 199, 160, 1) 100%
  );
}

header.masthead {
  position: relative;
  background-color: #007bff;
  background-color: rgb(0, 123, 255);
  background-image: linear-gradient(
    -45deg,
    rgba(0, 123, 255, 1) 0%,
    rgba(77, 199, 160, 1) 100%
  );
  background-image: url("../img/shutterstock_655998274.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.alert {
  border-radius: 0.5rem;
  border-width: 0;
}

.alert-light {
  background-color: #f3f3f3;
  border-color: #fdfdfe;
}

.modal-content {
  border-radius: 1rem;
  padding: 0.5rem 1rem;
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
  /* background-size: contain; */
  height: 100%;
  position: relative;
  background-position: center;
  max-width: 100%;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
  box-shadow: 0px 5px 5px 0px #adb5bd;
}

.call-to-action {
  position: relative;
  background-color: #343a40;
  background: url("../img/shutterstock_655998274.webp") no-repeat center center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  min-height: 350px;
}

/* FOOTER */
.footer h4,
.footer h5,
.footer h6,
.footer .h1,
.footer .h2,
.footer .h3,
.footer .h4,
.footer .h5,
.footer .h6 {
  color: #fff;
}

.footer img {
    max-width: 150px;
}

.table thead th {
  border-top: none;
}

.btn-success {
  background-color: rgb(77, 199, 160);
  border-color: rgb(77, 199, 160);
}

.btn-success:hover {
  background-color: rgb(35, 148, 112);
  border-color: rgb(35, 148, 112);
}

.btn-cancel {
  float: right;
  margin-bottom: 3rem;
}

/* POPOVERS */
.popover {
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  border-color: #fff;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.3);
}

.popover-header {
  background: transparent;
}

/* TELECARE CARD */
.card-telecare {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.card-telecare.main {
  min-height: 500px;
  padding-bottom: 7rem;
}

.card-telecare .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.25rem;
}

.card-telecare .card-img-left {
  width: 60%;
  background-size: cover;
}

.card-telecare.signin .card-img-left {
  /* Link to your background image using in the property below! */
  background: scroll center url("../img/shutterstock_1725066934.webp");
  background-size: 190%;
}

.card-telecare.signin .card-img-left.register {
  /* Link to your background image using in the property below! */
  background: scroll center url("../img/shutterstock_1492797776.webp");
  background-position-x: 90%;
  background-size: cover;
  flex-shrink: 0;
  min-width: 50%;
  min-height: 50%
}

.card-telecare.signin .card-img-left.physician {
  background: scroll center
    url("../img/shutterstock_111795644.webp");
  background-position-x: 100%;
  background-size: cover;
  flex-shrink: 0;
  min-width: 50%;
  min-height: 50%
}

.card-telecare.signup .card-img-left {
  background: scroll center url("../img/online-appointment.webp");
}

.card-telecare .card-body {
  padding: 2rem;
}

.card-telecare .card-body.btn {
  padding: 1rem;
}

.btn-responsive-1 .features-icons-icon {
  font-size: 5rem;
}

.btn-responsive-2 .features-icons-icon,
.btn-responsive-3 .features-icons-icon,
.btn-responsive-4 .features-icons-icon,
.btn-responsive-5 .features-icons-icon {
  font-size: 3rem;
}

@media (max-width: 961px) {
  .card-telecare .card-body.btn {
    padding: 0.75rem;
  }
  .btn-responsive-1,
  .btn-responsive-2,
  .btn-responsive-3,
  .btn-responsive-4,
  .btn-responsive-5 {
    position: fixed;
    bottom: 15px;
    z-index: 1000;
    opacity: 0.9;
    margin: 0 !important;
  }

  .btn-responsive-1 {
    right: 15px;
  }

  .btn-responsive-1 .features-icons-icon {
    font-size: 4rem;
  }

  .btn-responsive-2,
  .btn-responsive-3,
  .btn-responsive-4,
  .btn-responsive-5 {
    max-width: 120px;
  }

  .btn-responsive-2 {
    left: 15px;
  }

  .btn-responsive-3 {
    left: 150px;
  }

  .btn-responsive-4 {
    right: 15px;
  }

  .btn-responsive-5 {
    right: 150px;
  }

  .card-telecare.btn-responsive-1 .card-title,
  .card-telecare.btn-responsive-2 .card-title,
  .card-telecare.btn-responsive-3 .card-title,
  .card-telecare.btn-responsive-4 .card-title,
  .card-telecare.btn-responsive-5 .card-title {
    font-size: 0.875rem;
  }
}

@media (max-width: 767px) {
  .btn-responsive-1  {
	max-width: 120px;
  }
 
  .btn-responsive-1 .features-icons-icon {
    font-size: 2.5rem;
  }

  .btn-responsive-2,
  .btn-responsive-3,
  .btn-responsive-4,
  .btn-responsive-5 {
    max-width: 85px;
  }

  .btn-responsive-2 {
    left: 15px;
  }

  .btn-responsive-3 {
    left: 115px;
  }

  .btn-responsive-5 {
    right: 115px;
  }

  .card-telecare.btn-responsive-1 .card-title,
  .card-telecare.btn-responsive-2 .card-title,
  .card-telecare.btn-responsive-3 .card-title,
  .card-telecare.btn-responsive-4 .card-title,
  .card-telecare.btn-responsive-5 .card-title {
    font-size: 0.8125rem;
  }

  .btn-responsive-2 .features-icons-icon,
  .btn-responsive-3 .features-icons-icon,
  .btn-responsive-4 .features-icons-icon,
  .btn-responsive-5 .features-icons-icon {
    font-size: 1.75rem;
  }
}

.btn-white {
  color: white;
}

/* SIGN IN STYLES */
.tc-bg {
  background: #007bff;
  background: rgb(0, 123, 255);
  background: linear-gradient(
    -45deg,
    rgba(0, 123, 255, 1) 0%,
    rgba(77, 199, 160, 1) 100%
  );
  margin-top: 6.5rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group textarea,
.form-label-group .form-control {
  height: auto;
  border-radius: 2rem;
}

.form-label-group-datePicker input{
  height: auto;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.form-label-group > input,
.form-label-group > textarea,
.form-label-group > label {
  padding: 1.4375rem 1.5rem 0.75rem;
}

.form-label-group textarea {
  min-height: 150px;
}

.form-label-group > select {
  padding: 0.75rem 1.25rem;
}
.additional textarea
{ min-height: 30px; 
 

}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
  padding-top: 0.375rem;
    padding-bottom: 0;
    font-size: 12px;
    color: #777;
}

.form-label-group input::-webkit-input-placeholder,
.form-label-group textarea::-webkit-input-placeholder,
.form-label-group .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder,
.form-label-group textarea:-ms-input-placeholder,
.form-label-group .form-control:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder,
.form-label-group textarea::-ms-input-placeholder,
.form-label-group .form-control::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder,
.form-label-group textarea::-moz-placeholder,
.form-label-group .form-control::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder,
.form-label-group textarea::placeholder,
.form-label-group .form-control::placeholder {
  color: rgb(128, 128, 128, 0.4);
}

.form-label-group input:not(:placeholder-shown),
.form-label-group textarea:not(:placeholder-shown),
.form-label-group .form-control:not(:placeholder-shown) {
  padding-top: 1.4375rem;
  padding-bottom: 0.75rem;
}

.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label,
.form-label-group .form-control:not(:placeholder-shown) ~ label {
  padding-top: 0.375rem;
  padding-bottom: 0;
  font-size: 12px;
  color: #777;
}

/* CUSTOM SWITCHES */
.tc-switch .tc-control-label {
  padding-left: 1.5rem;
  font-size: 1.25rem;
  vertical-align: top;
}

.tc-switch .tc-control-label::before {
  height: 1.5rem;
  left: -2.25rem;
  width: 3rem;
  border-radius: .875rem;
  top: 0.25rem;
}

.tc-switch .tc-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: .875rem;
}

.tc-switch .tc-control-input:checked~.tc-control-label::after {
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}

/* LIST TABLE */
/* generic */

body {
  background-color: #eee;
  color: #444;
}

.list-table ul:nth-child(odd) {
  background-color: #efefef;
}

.list-table ul:nth-child(even) {
  background-color: #fff;
}

@media screen and (min-width: 600px) {
  .list-table {
    display: table;
    margin: 1rem 0;
    width: 100%;
  }

  .list-table ul {
    display: table-row;
  }

  .list-table ul:first-child li {
    color: #fff;
  }

  .list-table ul > li {
    display: table-cell;
    padding: 0.5em 1em;
  }
}

@media screen and (max-width: 599px) {
  .list-table ul {
    border: solid 1px #ccc;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0.5em 1em;
  }

  .list-table ul:first-child {
    display: none;
  }

  .list-table ul > li {
    display: block;
    padding: 0.75rem;
  }

  .list-table ul:nth-child(odd) > li + li {
    border-top: solid 1px #ccc;
  }

  .list-table ul:nth-child(even) > li + li {
    border-top: solid 1px #eee;
  }

  .list-table ul > li:before {
    color: #000;
    content: attr(data-label);
    display: inline-block;
    font-size: 75%;
    font-weight: bold;
    text-transform: capitalize;
    vertical-align: top;
    width: 50%;
  }

  .list-table p {
    margin: -1em 0 0 50%;
  }
}

@media screen and (max-width: 349px) {
  .list-table ul > li:before {
    display: block;
  }

  .list-table p {
    margin: 0;
  }
}

.logo-font {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.logo-font img {
  max-width: 180px;
  width: 100%;
}

.section-content-block + .section-content-block {
  margin-top: 30px;
}

.section-content-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 0 1rem;
}



/* TIMELINE */
.timeline {
  list-style-type: none;
  position: relative;
  margin-bottom: 2rem;
  padding: 0;
}

/* .timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 2rem;
	width: 2px;
	height: 100%;
	z-index: 400;
} */

.timeline > li {
  margin: 1rem 1rem 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
  border-color: #c3c3c3;
  border: 1px solid rgb(195, 195, 195);
  border-left: 10px solid rgb(195, 195, 195);
}

/* .timeline > li:before {
	content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border-color:  #c3c3c3;
    border: 3px solid rgb(195, 195, 195);
	left: -43px;
    top: 1.25rem;
    width: 20px;
    height: 20px;
    z-index: 400;
} */

.timeline > li > div {
  display: flex;
}

.timeline > li > div > div:first-child {
  margin-right: auto;
}

.timeline > li > div > div:last-child {
  margin-left: auto;
}

.timeline > li > div > div:last-child > a {
  margin: 0 .5rem;
}

@media(max-width: 767px) {
  .timeline > li > div {
    flex-direction: column;
  }

  .timeline > li > div > div:first-child {
      margin: 0;
  }

  .timeline > li > div > div:last-child {
    margin: 1rem 0 0;
  }

  .timeline > li > div > div:last-child > a {
    margin: 0 1rem;
  }

  .timeline > li > div > div:last-child > a i{
    font-size: 1.25rem;
  }
}

.timeline.upcoming > li {
  border-color: #007bff;
}

.timeline.upcoming > li:before {
  border-color: #007bff;
}

.timeline + .pager {
  margin-top: -2rem !important;
}

/* RADIO TILE GROUPS */
.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.radio-tile-group .input-container {
  position: relative;
  height: auto;
  width: 100%;
  margin: 0.5rem 1.25rem;
}

@media (min-width: 768px) {
  .radio-tile-group .input-container {
    height: 10rem;
    width: 10rem;
    margin: auto;
  }

  .radio-tile-group .input-container:first-child {
    margin-right: 2rem;
  }

  .radio-tile-group .input-container:last-child {
    margin-left: 2rem;
  }

  .radio-tile-group.wide-tile .input-container {
    width: 20rem;
    margin: auto auto 2rem;
  }
}

.radio-tile-group .input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #007bff;
  border-radius: 5px;
  padding: 1rem;
  color: #007bff;
  transition: transform 300ms ease;
  text-align: center;
}

.radio-tile-group .input-container .radio-tile .icon img,
.radio-tile-group .input-container .radio-tile .icon svg,
.radio-tile-group .input-container .radio-tile .icon .fa {
  fill: #007bff;
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.25em;
}

.radio-tile-group .input-container .radio-tile .radio-tile-label {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: #007bff;
}

.radio-tile-group .input-container .radio-tile .radio-tile-label > span {
  display: inline-block;
}

@media (min-width: 768px) {
  .radio-tile-group .input-container .radio-tile .radio-tile-label > span {
    display: block;
  }
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile {
  background-color: #007bff;
  border: 2px solid #007bff;
  color: white;
  transform: scale(1.1, 1.1);
}

.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .icon
  img,
.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile+
  .icon
  svg,
.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .icon
  .fa {
  fill: white;
  background-color: #007bff;
}

.radio-tile-group
  .input-container
  .radio-button:checked
  + .radio-tile
  .radio-tile-label {
  color: white;
  background-color: #007bff;
}


/* FLOW NAV TOOLS */
.card + .flow-nav-tools {
  width: 100%;
  margin-top: -120px;
  position: absolute;
  display: flex;
}

.flow-nav-tools > .control-nav {
  width: 33.33%;
  margin-left: -30px;
}

.flow-nav-tools > .control-nav:first-child {
  margin-right: auto;
}

.flow-nav-tools > .control-nav:last-child {
  margin-left: auto;
}

.flow-nav-tools + .btn-cancel {
  margin-top: -35px;
}


@media (max-width: 767px) {
	
	.card + .flow-nav-tools {
    position: relative;
    margin-bottom: 1rem;
    margin-top: -4rem;
  }
  
  .flow-nav-tools > .control-nav {
	margin: auto;
	font-size: 1rem;
  }

  .flow-nav-tools > span {
	margin-top: -40px !important;
    position: absolute;
    width: 100%;
    text-align: center;
  }
}

.opacity-0 {
  opacity: 0;
}
.media.selected {
  background-color: rgba(0, 123, 255, 0.25);
}

.media img {
  max-width: 120px;
}

.tile-list-group {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.tile-list-group.condensed {
  padding: 10px;
}

/* OWL OVERRIDES */
.owl-carousel .owl-item img {
  display: inline-block;
}

/* FONT AWESOME OVERRIDES */
.fa-1-5x {
  font-size: 1.5em;
}

@media (max-width: 768px) {
  .fa-md-1-5x {font-size: 1.5em;}
  .fa-md-2x {font-size: 2em;}
  .fa-md-3x {font-size: 3em;}
}

/* UTILITY OVERRIDES */
.text-light {
  color: #dfe0e2 !important;
}

.fwb {
  font-weight: bold;
}

[v-cloak] {
  display: none;
}

/*date picker*/
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input{
  width: inherit;
  padding-left: 25px
}


/* #react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
} */

.pagination {
  /* display: inline-block; */
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  color: #007bff;
  margin-right: auto;
  margin-left: auto
}
.pagination .disabled {
  color:grey;
  opacity: 0.8;
  cursor: default !important;
}
li.disable,
    li.disabled a {
      cursor: default;
    }

.highlight-front a{ 
  color: white;
}

@media (min-width: 1300px) {
  .chat-special {
    width: 500
  }
}

@media (min-width: 1100px) {
  .chat-special {
    width: 400
  }
}

.actionsStyle {
  text-align: center
}

.avatar {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.ph-info-label {
  color: #0056b3;
}

@media (min-width: 1160px) {
  .table-responsive {
    overflow-x: hidden;
  }
}

path {
  pointer-events: all;
}

path:hover {
  opacity: 0.50;
  cursor: pointer;
}

g text {
  font-size: 15px !important;
  font-weight: bold;
}
.us-state-map{
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.booking-datepicker {
  width: max-content
}

.centeHeader {
  position: relative;
  
  padding: 10px;
  text-align: center
  }